/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {MenuItem, useAuth} from "../../../../app/modules/auth";
import {useLocation} from "react-router-dom";

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const location = useLocation();

  const payHistoryPath = 'pay_history';
  const isPayHistoryActive = location.pathname.includes(payHistoryPath);
  // useEffect(() => {
  //   setForceUpdate(prev => prev + 1);
  // }, [location])
  const {menu} = useAuth()

  const renderMenu = (menuItems: MenuItem[]) => {
    return menuItems.map((item) => {
      if (item.pages && item.pages.length > 0) {
        return (
          <AsideMenuItemWithSub
            key={item.menuId}
            to={item.menuUrl || '#'}
            title={item.menuNm}
            fontIcon={item.fontIcon || 'bi bi-circle'}
          >
            {renderMenu(item.pages)}
          </AsideMenuItemWithSub>
        )
      } else {
        return (
          <AsideMenuItem
            key={item.menuId}
            to={item.menuUrl}
            title={item.menuNm}
            hasBullet={true}
            fontIcon={item.fontIcon || ''}
          />
        )
      }
    })
  }
  return <>{renderMenu(menu)}</>
}


